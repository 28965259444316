<template>
  <b-card>
    <p>Fecha de creación</p>
    <div class="w-30">
      <b-form-datepicker v-model="filterDateString" class="mb-2"></b-form-datepicker>
    </div>
    <table-shipments 
      :loading="loading"
      :dataTable="dataComponents.listShipment"
      :pagination="pagination"
      @changePagination="changePagination($event)"
      @changeLimit="changeLimit($event)"></table-shipments>
  </b-card>
</template>
<script>
import TableShipments from './components/TableShipments.vue'
import BaseServices from '@/store/services/index'
import {
  BFormDatepicker
} from 'bootstrap-vue'
export default {
  data() {
    return {
      baseService: new BaseServices(this),
      dataComponents: {
        listShipment: []
      },
      loading: {
        first: false
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        noShowDigits: true
      },
      filterDate: new Date(),
      filterDateString: '',
      carrierCode: ''
    }
  },
  watch: {
    'filterDateString': {
      handler(value) {
        this.getShipmentData()
      },
      deep: true
    }
  },
  components: {
    TableShipments,
    BFormDatepicker
  },
  mounted() {
    this.filterDate.setMonth(this.filterDate.getMonth() - 1)
    this.filterDateString = this.filterDate.toISOString()
  },
  methods: {
    changeDate(value) {
      this.getShipmentData()
    },
    changePagination(value) {
      this.pagination.page = value
      this.getShipmentData()
    },
    changeLimit(value) {
      this.pagination.limit = value
      this.getShipmentData()
    },
    getShipmentData() {
      this.loading.first = true
      this.carrierCode =  this.$session.get('cas_user').global_scope?.carrier_code[0] ?? ''
      if (this.carrierCode !== '') {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
          carrier_code: this.carrierCode
        }
        const queryParams = this.setQueryListShipment()
        this.baseService.callService('getShipmentsCarriers', queryParams, params)
          .then(response => {
            this.dataComponents.listShipment = response.data || []
            // if (response.data) this.pagination.total = response.pagination.totalCount
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.loading.first = false
            }, 2000)
          })
      }
    },
    setQueryListShipment() {
      return {
        page: this.pagination.page,
        limit: this.pagination.limit,
        date_from: this.$options.filters.moment(this.filterDateString, 'YYYY-MM-DD')
      }
    }
  }
}
</script>