<template>
  <b-card>
    <div v-show="!loading.first">
      <table-render :schema="schema" :rows="dataTable" :fixed="true">
        <template #statusSlot="scope">
          <feather-icon icon="CircleIcon" 
            :style="[{'color': `${scope.rowdata.status_color ? scope.rowdata.status_color : 'red'}`, fill: `${scope.rowdata.status_color ? scope.rowdata.status_color : 'red'}`}] " />
          {{scope.rowdata.status.name}}
        </template>
        <template #InstructionSlot="scope">
          <div>
            <i v-if="scope.rowdata.instruction" class="fa-solid fa-ticket"></i>{{ scope.rowdata.instruction ? 'Instrucción' : '' }}
          </div>
        </template>
        <template #withdrawalDateSlot="scope">
          {{formatDate(scope.rowdata.updated_at)}}
        </template>
        <template #createDateSlot="scope">
          {{formatDate(scope.rowdata.status.created_at)}}
        </template>
      </table-render>
      <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
    </div>
    <div v-show="loading.first">
      <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="8"/>
    </div>
  </b-card>
</template>
<script>
export default {
  props: ['dataTable', 'loading', 'pagination'],
  data() {
    return {
      schema: []
    }
  },
  mounted() {
    this.setData()
  },
  watch: {
    'pagination.page'(value) {
      this.$emit('changePagination', value)
    },
    'pagination.limit'(value) {
      this.$emit('changeLimit', value)
    }
  },
  methods: {
    setData() {
      this.schema = [
        {label: 'Orden de flete', key: 'tracking_number', sortable: true},
        {label: 'Estado', key: 'statusSlot', useSlot: true},
        {label: 'Solicitudes', key: 'InstructionSlot', useSlot: true},
        {label: 'Bodega', key: 'warehouse_address.name', sortable: true},
        // {label: 'Tel. Bodega', key: 'warehouse_address.warehouse_phone', sortable: true},
        {label: 'Domicilio', key: 'shipping_address.full_address', sortable: true},
        {label: 'Comuna', key: 'shipping_address.place', sortable: true},
        {label: 'Fecha de retiro', key: 'updated_at', sortable: true},
        {label: 'Fecha de creación', key: 'created_at', sortable: true}
      ]
    },
    formatDate(date) {
      return date ? this.$options.filters.dbDateToFormat(date, 'dd-LL-y T') : ''
    } 
  }
}
</script>